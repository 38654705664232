.partners{
   
    display: flex;
    justify-content: center;
    background: black;
  
    position: relative;
}

.partners__container{
    
    
}

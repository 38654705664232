.popup{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup__inactive{
    display: none;
}

.popup__container{
    width: 80%;
    height: 80%;
    position: relative;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content__popup{
    width: 80%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 1rem;
    text-align: center;
}

.content__popup h1{
    color: white;
}

.content__popup p{
    color: rgb(230, 203, 203);

}

.bg__popup{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
    position: absolute;
    z-index: -1;
}

.popup__close{
    position: absolute;
    right: 2rem;
    top: 2rem;
}

.social__icons{
    display: flex;
    align-items: center;
    gap: 2rem;
    color: white;
    font-size: 2rem;
}
.social__icon{
    transition: all 300ms ease;
}

.social__icon:hover{
    transform: scale(120%);
}
.title__popup{
    color: white;
    text-align: center;
}



.car__container{

    position: absolute;
    bottom: 0;
    left: 5%;

    width: 35rem;
}
.civic{
    width: 100%;
 

}
.wheel1{
    position: absolute;
   
    width: 13.5%;
    bottom: 0;
    left: 21%;
    animation: Rotate 6s linear infinite;
}
.wheel2{
    position: absolute;

    width: 13.5%;
    bottom: 0;
    left: 71%;
    animation: Rotate 6s linear infinite;
}


@keyframes Rotate {
    100%{
        transform: rotate(1turn);
    }

}
.text__design{
    width: 70vw;
}


.eightbit-btn {
    background: #92CD41;
    display: inline-block;
    position: relative;
    text-align: center;
    /* font-size: 15px; */
    /* padding: 15px; */
    font-family: "Press Start 2P", cursive;
    text-decoration: none;
    color: white;
    box-shadow: inset -4px -4px 0px 0px #4AA52E;
  }
  .eightbit-btn:hover, .eightbit-btn:focus {
    background: #76c442;
    box-shadow: inset -6px -6px 0px 0px #4AA52E;
  }
  .eightbit-btn:active {
    box-shadow: inset 4px 4px 0px 0px #4AA52E;
  }
  .eightbit-btn:before, .eightbit-btn:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }
  .eightbit-btn:before {
    top: -6px;
    left: 0;
    border-top: 6px black solid;
    border-bottom: 6px black solid;
  }
  .eightbit-btn:after {
    left: -6px;
    top: 0;
    border-left: 6px black solid;
    border-right: 6px black solid;
  }
  .eightbit-btn--reset {
    background: #E76E55;
    box-shadow: inset -4px -4px 0px 0px #8C2022;
  }
  .eightbit-btn--reset:hover, .eightbit-btn--reset:focus {
    background: #CE372B;
    box-shadow: inset -6px -6px 0px 0px #8C2022;
  }
  .eightbit-btn--reset:active {
    box-shadow: inset 4px 4px 0px 0px #8C2022;
  }

  @media screen and (max-width: 1320px){
    .car__container{
        width: 40vw;
    }
  }

@media screen and (max-width: 820px){
    .text__design{
        width: 80vw;
    }
    .content__popup p{
        font-size: 0.6rem;
    }
    .content__popup h1{
        font-size: 0.9rem;
    }

    .title__popup{
        font-size: 0.5rem;
    }

    .popup__container{
        width: 90%;
        height: 90%;
    }
    .car__container{
        width:85vw;
        left: 0;
       }

       .content__popup{
        width: 85%;
        height: 50%;
        gap: 1rem;
    
    }
    
}

@media screen and (max-width: 340px){
    .text__design{
        width: 80vw;
    }
    .content__popup p{
        font-size: 0.4rem;
    }
    .content__popup h1{
        font-size: 0.7rem;
    }

    .title__popup{
        font-size: 0.4rem;
    }

    .popup__container{
        width: 90%;
        height: 90%;
    }
    .car__container{
        width:75vw;
        left: 0;
       }

       .content__popup{
        width: 85%;
        height: 60%;
        gap: 1rem;
    
    }
    .social__icons{
     
        font-size: 1rem;
    }
    .popup__close button{
     
        font-size: 0.5rem;
    }
    .popup__close{
        right: 0.9rem;
        top: 0.9rem;
    }
}
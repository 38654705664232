.bg{
    position: absolute;
    height: 100vh;
    left: 0;
   
    bottom: 0;
    width: 100vw;
    z-index: -2;
    object-fit: cover;
}

.frame{
    width: 45vw;
    transform: rotate(10deg);
}


.car__container{

    position: absolute;
    bottom: 0;
    left: 5%;

    width: 35rem;
}
.civic{
    width: 100%;
 

}
.wheel1{
    position: absolute;
   
    width: 13.5%;
    bottom: 0;
    left: 21%;
    animation: Rotate 6s linear infinite;
}
.wheel2{
    position: absolute;

    width: 13.5%;
    bottom: 0;
    left: 71%;
    animation: Rotate 6s linear infinite;
}


@keyframes Rotate {
    100%{
        transform: rotate(1turn);
    }

}
.text__design{
    width: 70vw;
}




@media (max-width: 1680px) {
    .bg{
        object-fit: cover;
    }
}

@media (max-width: 820px) {
   .car__container{
    width: 80vw;
    left: 0;
   }
}

@media (max-width: 450px) {
    .text__design{
        width: 100vw;
    }
    .car__container{
        width: 100vw;
        left: 0;
       }

}
.container{
    margin: 1.8rem;
}

.minting__section{
    display: flex;
    align-items: center;
    width: 100%;
    color: white;
}


.left__minting{
    /* background: blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 50%;
    border-right: 1px solid white;
}
.mint__content{
    /* background: aqua; */
    width: 70%;
    text-align: center;
}

.mint__count{
    /* background: yellow; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
}
/* .mint__count button{
    background: var(--color-dark);
    padding: 0.5rem 3rem;
    color: white;
    font-size: 3rem;
    transition: var(--transition);
    box-shadow: var(--box-shadow);
} */
/* .mint__count button:hover , .mint__btn:hover{
    background: transparent;
    border: 1px solid white;
    } */

/* .mint__btn{
background: var(--color-dark);
padding: 1rem;
color: white;
font-size: 2rem;
width: 70%;
transition: var(--transition);
box-shadow: var(--box-shadow);
} */
.right__minting{
    width: 50%;
    display: flex;
    justify-content: center;
}


.eightbit-btn {
    background: #92CD41;
    display: inline-block;
    position: relative;
    text-align: center;
    font-size: 30px;
    padding: 20px;
    font-family: "Press Start 2P", cursive;
    text-decoration: none;
    color: white;
    box-shadow: inset -4px -4px 0px 0px #4AA52E;
  }
  .eightbit-btn:hover, .eightbit-btn:focus {
    background: #76c442;
    box-shadow: inset -6px -6px 0px 0px #4AA52E;
  }
  .eightbit-btn:active {
    box-shadow: inset 4px 4px 0px 0px #4AA52E;
  }
  .eightbit-btn:before, .eightbit-btn:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }
  .eightbit-btn:before {
    top: -6px;
    left: 0;
    border-top: 6px black solid;
    border-bottom: 6px black solid;
  }
  .eightbit-btn:after {
    left: -6px;
    top: 0;
    border-left: 6px black solid;
    border-right: 6px black solid;
  }
  .eightbit-btn--reset {
    background: #E76E55;
    box-shadow: inset -4px -4px 0px 0px #8C2022;
  }
  .eightbit-btn--reset:hover, .eightbit-btn--reset:focus {
    background: #CE372B;
    box-shadow: inset -6px -6px 0px 0px #8C2022;
  }
  .eightbit-btn--reset:active {
    box-shadow: inset 4px 4px 0px 0px #8C2022;
  }

@media screen and (max-width: 1160px){
    .minting__section{
        flex-direction: column;
        align-items: center;
        height: 100%;
        gap: 3rem;
    }
    .left__minting{
        width: 100%;
        border-right: none;
        text-align: center;
        align-items: center;   
        
    }
    .right__minting{
        width: 80%;
        height: 100%;
        align-items: center;
        border-top: 1px solid white;
        padding-top: 5rem; 

    }
}

@media screen and (max-width: 560px){
    .mint__count{
       
        width: 90%;
    }
    .right__minting{
        padding-top: 2rem;
    }
}
.mint__now{
    font-size: 25px;
    padding: 15px;
  
}
 .eightbit-btn {
    background: #92CD41;
    display: inline-block;
    position: relative;
    text-align: center; 
    /* font-size: 15px; */
    /* padding: 15px; */
     font-family: "Press Start 2P", cursive;
    text-decoration: none;
    color: white;
    box-shadow: inset -4px -4px 0px 0px #4AA52E;
  }
  .eightbit-btn:hover, .eightbit-btn:focus {
    background: #76c442;
    box-shadow: inset -6px -6px 0px 0px #4AA52E;
  }
  .eightbit-btn:active {
    box-shadow: inset 4px 4px 0px 0px #4AA52E;
  }
  .eightbit-btn:before, .eightbit-btn:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }
  .eightbit-btn:before {
    top: -6px;
    left: 0;
    border-top: 6px black solid;
    border-bottom: 6px black solid;
  }
  .eightbit-btn:after {
    left: -6px;
    top: 0;
    border-left: 6px black solid;
    border-right: 6px black solid;
  }
  .eightbit-btn--reset {
    background: #E76E55;
    box-shadow: inset -4px -4px 0px 0px #8C2022;
  }
  .eightbit-btn--reset:hover, .eightbit-btn--reset:focus {
    background: #CE372B;
    box-shadow: inset -6px -6px 0px 0px #8C2022;
  }
  .eightbit-btn--reset:active {
    box-shadow: inset 4px 4px 0px 0px #8C2022;
  } 


  @media screen and (max-width: 1160px){
    .mint__now{
      font-size: 15px;
    }

  }
.clock {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(270deg, #051817, #0e3839); */
    /* height: 180px; */
    /* width: 360px; */
    color: white;
    text-shadow: 1px 1px 7px;
    /* border-radius: 15px; */
    /* border: 2px solid silver; */
    /* box-shadow: 0 0 5px 25px; */
  }
  
  .clock section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .clock section p {
    font-size: 2vw;
  }
  .clock section small {
    color: white;
    text-shadow: none;
  }

  @media (max-width: 940px) {

    .clock section p{
      font-size: 4vw;
    }
  }
  @media (max-width: 450px) {

    .clock section {
        padding: 0;
    }
    .clock section small{
        font-size: 2vw;
    }
  }
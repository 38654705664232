.eightbit-btnc {
    background: #92CD41;
    display: inline-block;
    position: relative;
    text-align: center;
    font-size: 15px;
    padding: 15px;
    font-family: "Press Start 2P", cursive;
    text-decoration: none;
    color: white;
    box-shadow: inset -4px -4px 0px 0px #4AA52E;
  }
  .eightbit-btnc:hover, .eightbit-btnc:focus {
    background: #76c442;
    box-shadow: inset -6px -6px 0px 0px #4AA52E;
  }
  .eightbit-btnc:active {
    box-shadow: inset 4px 4px 0px 0px #4AA52E;
  }
  .eightbit-btnc:before, .eightbit-btnc:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }
  .eightbit-btnc:before {
    top: -6px;
    left: 0;
    border-top: 6px black solid;
    border-bottom: 6px black solid;
  }
  .eightbit-btnc:after {
    left: -6px;
    top: 0;
    border-left: 6px black solid;
    border-right: 6px black solid;
  }